import { EChatBotScenario } from '@ucheba/store/chat-bot/types'

export const codeLength = 4

export const scenariosByReqTargets = {
  ege_lead_free_lesson: EChatBotScenario.freeLessonEgeRequest,
  oge_lead_free_lesson: EChatBotScenario.freeLessonEgeRequest,
  ege_lead_free_test: EChatBotScenario.testEgeRequest,
  oge_lead_free_test: EChatBotScenario.testEgeRequest,
  ege_lead_request: EChatBotScenario.egeRequest,
  oge_lead_request: EChatBotScenario.egeRequest,
  proforientation_request: EChatBotScenario.egeRequest,
}

export const targetsForWhatsApp = [
  `ege_request`,
  `free_lesson_ege_request`,
  `test_ege_request`,
]

import { useEffect, useMemo, useState } from 'react'
import { splitSecondsByMinutes } from '../helpers/string'

interface IUseTimerProps {
  seconds?: number
  secondsByMinutes: boolean
}

interface IUseTimer {
  (props: IUseTimerProps): {
    timeLeft: string
    isTimeLeft: boolean
  }
}

/* Создает таймер с обратным отсчетом */
export const useTimer: IUseTimer = ({ seconds = 60, secondsByMinutes }) => {
  const [timeForNewCode, setTimeForNewCode] = useState(seconds)
  const [isTimeLeft, setIsTimeLeft] = useState(!seconds)

  const timeLeft = useMemo(() => {
    return String(
      secondsByMinutes ? splitSecondsByMinutes(timeForNewCode) : timeForNewCode
    )
  }, [secondsByMinutes, timeForNewCode])

  useEffect(() => {
    setTimeForNewCode(seconds)
    setIsTimeLeft(!seconds)
  }, [seconds])

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeForNewCode) {
        setTimeForNewCode(Number(timeForNewCode - 1))
      } else {
        setIsTimeLeft(true)

        clearInterval(interval)
      }
    }, 1000)

    return (): void => {
      clearInterval(interval)
    }
  }, [timeForNewCode])

  return {
    timeLeft,
    isTimeLeft,
  }
}

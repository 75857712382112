import { IPageProps } from '@ucheba/utils/helpers/components/types'
import { ISerializedError } from '@ucheba/utils/helpers/core/types'
import { ELoading } from '@ucheba/store/utils/response/types'

export interface IPaymentPageProps extends IPageProps<IContent> {}

export interface IContent {}

export enum EPaymentStatus {
  success = 'success',
  canceled = 'canceled',
}

export enum EPaymentQuery {
  requestId = 'requestId',
  status = 'status',
  rightNow = 'rightNow',
  fromMarathon = 'fromMarathon',
  isByMonthPayment = 'isByMonthPayment',
}

export interface IUsePaymentPageCore {
  (): {
    status: EPaymentStatus | undefined
    getConfirmationUrl(): void
    paymentLoading: ELoading
    paymentError: ISerializedError
    isSuccessStatus: boolean
  }
}

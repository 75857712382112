import { clientProductsRequestsSlice } from './index'
import { IToken } from '../../auth/types'

export interface IClientProductsRequestsState {
  [key: string]: ReturnType<typeof clientProductsRequestsSlice.reducer>
}

export interface IClientProductsRequestsEntity {
  requestId: number
  phoneConfirmed: boolean
  token: IToken | null
}

export enum EClientProductsRequestsDataKeys {
  productType = 'productType',
  count = 'count',
  productId = 'productId',
  requestId = 'requestId',
  subjectId = 'request_target_ege_subject_id',
  funnel = 'funnel',
  requestTarget = 'requestTarget',
  referralCode = 'referralCode',
  lovkoProClickId = 'lovkoProClickId',
  edPartnersClickId = 'edPartnersClickId',
  specialOfferCode = 'specialOfferCode',
  additionalData = 'additionalData',
  amoTag = 'amoTag',
}

import { chatBotSlice } from './index'

export interface IChatBotState {
  [key: string]: ReturnType<typeof chatBotSlice.reducer>
}

export interface IChatBot {
  redirectUrl: string
}

export interface IIsAllowedResponse {
  isAllowed: boolean
}

export enum EChatBotScenario {
  institutionInfo = 'institution_info',
  egeMarathon = 'ege_marathon',
  egeWebinar = 'ege_webinar',
  proforientationMarathon = 'proforientation_marathon',
  openDoors = 'opendoors',
  vkSubscribe = 'vk_subscribe',
  tgSubscribe = 'tg_subscribe',
  admissionWeek = 'admission_week',
  egeStart = 'ege_start',
  egeDemo = 'ege_demo',
  engineeringProjects = 'engineering_projects',
  usefulMaterialsEge = 'useful_materials_ege',
  explainEgeDemo = 'explain_ege_demo',
  testEgeRequest = 'test_ege_request',
  freeLessonEgeRequest = 'free_lesson_ege_request',
  egeRequest = 'ege_request',
}

export enum EMessengerType {
  vk = 'vk',
  telegram = 'telegram',
  whatsapp = 'whatsapp',
}
